.picture1 {
  width: auto;
  height: 150px;
  color: #ffffff;
  padding-top: 50px;
  margin: 15px auto;
  overflow: hidden;
  transition: all 0.2s;
}
.picture1:hover {
  border: 1px dashed #fff;
}
